import debounce from "lodash/debounce";
import Glide, { Breakpoints } from "@glidejs/glide";
import Splide from '@splidejs/splide';


document.addEventListener( 'DOMContentLoaded', function() {
  if (document.querySelector(".image--slider")) {
    new Splide( '.image--slider', {
      type: 'loop',
      perPage:1,
      arrows:false,
      direction:'ltr',
      mediaQuery:'min',
      breakpoints: {
        640: {
          direction: 'ttb',
          height:'50rem'
        },
      }
    }).mount();
  };

  if (document.querySelector(".application--slider")) {
    new Splide( '.application--slider', {
      type: 'loop',
      perPage:1,
      gap:'1rem',
      arrows:false,
      mediaQuery:'min',
      breakpoints: {
        640: {
          destroy: true,
        },
      }
    }).mount();
  }
});
